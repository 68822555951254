header.devotional{
  position: relative;
  padding:rems(30) 0;
  background-color: #90d0d3;
  z-index: 2;
  .logo-desktop{
    width: rems(150);
    max-width: 100%;
  }
}
#main.devotional{
  width: 100%;
  overflow-x: hidden;
  font-family: 'new-kansas', serif;
  .container{
    max-width: rems(1350);
    &.wrap{
      display: flex;
      @media screen and (max-width: 1000px){
        flex-direction: column;
        align-items: center;
      }
    }
  }
  section{
    position: relative;
    .stripe-l{
      position: absolute;
      z-index: 3;
      top: 0;
      left: rems(-100);
      transform: translateY(-50%) rotate(180deg);
    }
    .stripe-r{
      position: absolute;
      z-index: 3;
      top: 0;
      right: rems(-100);
      transform: translateY(-50%); 
    }
    &:nth-child(1){
      background-color: #ea8b5f; 
      background-image: url('../../dist/img/devotional/flower-bg-2.png');
      background-size: auto;
      video{
        display: block;
        margin: 0 auto;
        height: 80vh;
        width: auto;
      }
      // padding: rems(150) 0;
      // background-color: #fff;
      // color: #000;
      
      // .flower{
      //   position: absolute;
      //   width: rems(70);
      //   bottom: rems(20);
      //   left: rems(50);
      // }
      
      // .flower:nth-child(2){
      //   top: rems(30);
      //   right: rems(20);
      //   bottom: auto;
      //   left: auto;
      // }
      // .flower:nth-child(3){
      //   right: rems(70);
      //   bottom: rems(60);
      //   left: auto;
      // }
      
    }
    &:nth-child(2){
      padding: rems(30) 0 1px;
      background-color: #90d0d3;
      color: #fff;
      @media screen and (max-width: 1000px){
        padding: rems(50) 0;
      }
      .flower{
        position: absolute;
        width: rems(70);
        top: 50%;
        right: 0;
        transform: translate(50%,-50%);
      }
      h2{
        font-family: $header-font;
        font-size: rems(70);
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          display: inline-block;
          width: rems(80);
        }
      }
      p{
        font-size: rems(28);
        font-weight: bold;
        line-height: 1.3;
      }
      .img{
        flex-basis: rems(460);
      }
      .info{
        flex: 1;
        padding: rems(30);
        text-align: center;
      }
    }
    &:nth-child(3){
      background-color: #ccd4b7;
      padding: rems(100) 0;
      
      .flower{
        position: absolute;
        width: rems(70);
        top: rems(-20);
        left: rems(30);
      }
      h2{
        width: max-content;
        max-width: 100%;
        margin: 0 auto;
        font-family: $header-font;
        font-weight: normal;
        position: relative;
        font-size: rems(90);
        color: #ea8b5f;
        @media screen and (max-width: 1000px){
          font-size: rems(50);
          text-align: center;
        }

        span{
          position: relative;
          display: block;
          width: 100%;
          
          &:nth-child(1){
            color: #fff;
            position: absolute;
            left: rems(8);
          }
        }
      }
      .btn-wrap{
        margin: rems(30) 0;
        display: flex;
        flex-wrap: wrap;
        gap: rems(40);
        justify-content: center;
        a{
          flex-basis: 40%;
          position: relative;
          @media screen and (max-width: 1000px){
            flex-basis: 100%;
          }
          span{
            display: block;
            font-weight: bold;
            position: relative;
            color: #ea8b5f;
            padding: 1.3em;
            text-align: center;
            font-size: rems(40);
            text-transform: uppercase;
            background-color: #fff;
            border-radius: rems(35);
            
            @media screen and (max-width: 1350px){
              font-size: rems(30);
            }
          }
          &::before{
            content: '';
            position: absolute;
            top: rems(-10);
            right: rems(-20);
            width: 100%;
            height: 100%;
            border-radius: rems(35);
            background-color: #90d0d3;
            transition: .3s all;
          }
          @include hover-focus{
            &::before{
              top: 0;
              right: 0;
            }
          }

        }
      }
    }
    &:nth-child(4){
      padding: rems(100) 0;
      background-color: #fef5bf;
      color: #ea8b5f;
      .flower{
        position: absolute;
        width: rems(70);
        top: rems(-30);
        right: rems(-20);
      }
      .container{
        max-width: rems(1250);
      }
      h2{
        font-family: $header-font;
        font-size: rems(110);
        font-weight: normal;
        @media screen and (max-width: 1000px){
          font-size: rems(50);
          text-align: center;
        }
      }
      .info{
        flex: 1;
        text-align: center;
        line-height: 1.4;
        p{
          font-size: rems(50);
          font-weight: bold;
          line-height: 1.3;
          @media screen and (max-width: 1000px){
            font-size: rems(30);
          }
        }
      }
      .img{
        flex-basis: rems(450);
      }
      .overlap{
        display: block;
        position: relative;
        transition: .3s all;
        @media screen and (max-width: 1000px){
          width: rems(300);
        }
        @include hover-focus{
          transform: rotate(10deg);
        }
        span{
          position: absolute;
          left: rems(10);
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          color: #fef5bf;

          font-family: $header-font;
          font-size: rems(80);
          @media screen and (max-width: 1000px){
            font-size: rems(50);
          }
        }
      }
    }
    &:nth-child(5){
      padding: rems(100) 0;
      background-color: #ea8b5f;
      color: #fff;
      .flower{
        position: absolute;
        width: rems(70);
        top: rems(30);
        left: rems(30);
      }
      h2{
        font-family: $header-font;
        font-size: rems(90);
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120%;
        margin-left: -20%;
        @media screen and (max-width: 1000px){
          margin: 0;
          width: 100%;
          text-align: center;
          font-size: rems(50);
        }
        
      }
      p{
        font-size: rems(28);
        font-weight: bold;
        line-height: 1.3;
        @media screen and (max-width: 1000px){
          font-size: rems(24);
        }
      }
      .img{
        flex-basis: rems(460);
      }
      .info{
        flex: 1;
        padding: rems(30);
        text-align: right;
        @media screen and (max-width: 1000px){
          padding: rems(30) 0;
          text-align: center;
        }
      }
    }
    &:nth-child(6){
      padding: rems(100) 0; 
      background-color: #90d0d3; 
      background-image: url('../../dist/img/devotional/flower-bg.png'), linear-gradient(to bottom, #90d0d3 0%, #90d0d3 50%, #f3b41a 50%, #f3b41a 100%);
      background-size: auto, cover;
      background-position: center;
      .container{
        max-width: rems(1000);
      }
      .container>div{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1000px){
          flex-direction: column;
          align-items: center;
        }
        img{
          width: rems(360);
        }
        &:nth-child(1){
          img:nth-child(1){
            transform: rotate(10deg);
          }
          img:nth-child(2){
            transform:  translateY(5rem) rotate(2deg);
            z-index: 2;
          }
          img:nth-child(3){
              transform: rotate(-15deg);
          }
        }
        &:nth-child(2){
          max-width: rems(780);
          margin: 3rem auto 0;
          img:nth-child(1){
            transform: rotate(-13deg);
          }
          img:nth-child(2){
              transform: rotate(9deg);
          }
        }
      }
    }
  }
  &+footer{
    padding: rems(40) 0 rems(10);
    background: #f3b41a;
    color: $black;
    .copyright{
      font-size: rems(12);
    }
    a{
      color: inherit;
      @include hover-focus{
        color: $white
      }
    }
  }
}