.btn{
  display: inline-block;
  font-size: 28px;
  color: $white;
  background-color: $pink;
  font-family: $header-font;
  padding: 14px 20px;
  text-transform: lowercase;
  border: none;
  @include hover-focus{
    background-color: $cyan;
  }
}