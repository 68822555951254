.videos{
  padding: 60px 0;
  background-color: $black;
  background-image: url('../../dist/img/video-bg.jpg');
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  .container{
    max-width: 100%;
    padding: 0;
  }
  .video-slider{
    .video-slide{
      transition: all .4s;
      max-width: 100%;
      width: 300px;
      height: 220px;
      position: relative;
      pointer-events: none;
      cursor: pointer;
      img,iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .4s;
      }
      i{
        color: $white;
        text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.188);
        font-size: 60px;
        position: absolute;
        z-index: 99;
        transition: all .4s;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
      }
      @include phone-up{
        width: 420px;
        height: 300px;
      }
      &.slick-active{
        opacity: 1;
      }
      &.slick-center{
        pointer-events: all;
      }
      @include hover-focus{
        i{
          transform: scale(1.1);
        }
      }
    }
    .slick-slide {
      margin: 0 20px;
    }
    .slick-list {
      margin: 0 -20px;
    }
    .slick-arrow{
      background-color: transparent;
      border: none;
      font-size: 30px;
      color: $white;
      position: absolute;
      bottom: -60px;
      z-index: 999;
      &.slick-prev{
        left: calc(50% - 40px);
      }
      &.slick-next{
        right: calc(50% - 40px);
      }
    }
  }
  
  @include tablet-up{
    padding: 120px 0;
    .video-slider{
      .video-slide{
        width: 512px;
        padding: 20px 0;
        height: 400px;
        iframe,img{
          height: calc(100% - 40px);
          top: 20px;
        }
        &.slick-center{
          width: 690px;
          padding: 0;
          iframe,img{
            height: 100%;
            top: 0;
          }
        }
      }
    }
  }
}