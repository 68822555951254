.tour{
  background-image: url('../img/tour-bg.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: $black;
  padding: 60px 0;
  text-align: center;
  @media screen and (max-width: 900px){
    background-image: url('../img/tour-bg.jpg'), linear-gradient(to right, $cyan, $pink);
    padding: 0px 0px 76vw;
    background-attachment: scroll;
    background-size: 150% auto;
    background-position: bottom center;
    background-repeat: no-repeat;
    
  }
  .container{
    background-color: #FFEAEAE5;
    padding-top: rems(30);
    max-width: rems(1300);
    
  }
  .cta{
    display: block;
    width: rems(600);
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  h2.heading{
    margin-top: rems(40);
    color: $black;
  }
  #events{
    display: grid;
    gap: 40px;
    justify-items: center;
    .event{
      gap: 10px;
      justify-items: center;
      text-align: center;
      display: none;
      width: 100%;
      font-size: 16px;
      &:nth-child(-n +5){
        display: block;
      }
    }
    &.all-events{
      .event{
        display: grid;
      }
    }
  }
  .btn-more-dates{
    margin-top: 40px;
  }
  @include tablet-up{
    padding: 120px 0;
    #events{
      .event{
        @include flex(row, space-between, center);
        display: none;
        &:nth-child(-n +5){
          display: flex;
        }
        text-align: left;
        .date{
          flex: 1;
        }
        .venue{
          flex: 1;
        }
        .location{
          flex: 1;
        }
      }
      &.all-events{
        .event{
          display: flex;
        }
      }
    }
    .btn-more-dates{
      margin-top: 80px;
    }
  }
}