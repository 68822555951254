.about{
  background-color: $black;
  background-image: url('../img/about-bg.jpg');
  background-size: cover;
  background-position: top center;
  padding: 60px 0;
  color: $white;

  .btn{
    font-size: rems(40);
    background-color: $cyan;
    color: $black;
    @include hover-focus{
      color: $white;
      background-color: $pink;
    }
  }
  .about-grid{
    display: grid;
    gap: 20px;
    justify-items: center;
    .content{
      p{
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 20px;
      }
    }
  }
  .embed-container{
    margin-top: rems(20);
  }
  @include tablet-up{
    padding: 120px 0;
    .about-grid{
      grid-template-columns: 1fr 1fr;
      justify-items: left;
      gap: 40px;
      img{
        width: 100%;
        object-fit: cover;
      }
      .content{
        p{
          font-size: 24px;
          line-height: 1.6;
        }
        @include flex(column, center, flex-start);
      }
    }
  }
}