.resources{
  min-height: 50vh;
  padding-top: rems(300);
  position: relative;
  background-image: url('../img/seamless-star-bg.jpg'), linear-gradient(to bottom, $cyan, $pink);
  background-size: cover;
  background-blend-mode: overlay;
  &.home{
    min-height: none;
    padding: rems(100) rems(20);
    background-image: url('../img/seamless-star-bg.jpg'), linear-gradient(to bottom, $cyan, $cyan);
  }
  .scrollbox{
    max-height: 70vh;
    padding: 0 rems(30) rems(30);
    height: rems(680);
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 10px;
    }
    
    &::-webkit-scrollbar-track-piece{
      background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb{
      background-color: #CBCBCBAA;
      border-radius: rems(5);
    }
    
    &::-webkit-scrollbar-thumb:hover{
      background-color: #909090AA;
    }
  }
  
  .music-item{
    padding-bottom: rems(100);
    display: flex;
    align-items: center;
    gap: rems(30);
    @media screen and (min-width: 901px){
      &:nth-child(even){
        flex-direction: row-reverse;
      }
    }
    @media screen and (max-width: 900px){
      flex-direction: column;
    }
    img{
      box-shadow: 0 rems(50) rems(50) #0000006E
    }
    h2{
      font-size: rems(55);
      letter-spacing: .005em;
      text-transform: uppercase;
      font-weight: normal;
      color: $black;
      margin-bottom: .6em;
      @media screen and (max-width: 500px){
        font-size: rems(40);
        text-align: center;
      }
    }
    >div{
      flex: 1;
    }
    .btns{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2%;
      .btn{ 
        flex-basis: 48%;
        padding: rems(10);
        text-align: center;
        margin-bottom: 2%;
        
      @media screen and (max-width: 500px){
        flex-basis: 100%;
      }
      }
    }
  }
}

.ios .resources{
  background-size: cover;
}