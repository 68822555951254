@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "header";
@import "footer";
@import "buttons";
@import "hero";
@import "music";
@import "tour";
@import "videos";
@import "about";
@import "shop";
@import "follow";
@import "feed";
@import "resources";
@import "devotional";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

h1,h2,h3,h4,p{
	margin: 0;
}

h2{
	&.heading{
		font-family: $header-font;
		color: $white;
		font-size: 62px;
		text-transform: capitalize;
		text-align: center;
		font-weight: normal;
		margin-bottom: 30px;
		letter-spacing: 2px;
		@include tablet-up{
			margin-bottom: 60px;
		}
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

main {
	section{
		position: relative;
	}
}

#bio-full{
	background-image: url('../img/about-bg.jpg');
	background-color: $black;
	background-size: cover;
	width: 100%;
	max-width: rems(800);
	font-size: rems(22);
	padding: rems(36);
	margin: rems(30) auto;
	position: relative;
	p{
		margin: 1em 0;
		line-height: 1.5;
	}

}

#popup{
	width: 100%;
	max-width: rems(800);
	margin: rems(30) auto;
	position: relative;

	
	.overlay-popup{
		position: relative;
		img{
			width: 100%;
			height: auto;
		}
		a{
			position: absolute;
			width: 40%;
			height: 18%;
			&:nth-child(2){
				left: 52%;
				top: 41%;
				width: 36%;
			}
			&:nth-child(3){
				left: 48%;
				top: 62%;
				width: 42%;
			}
		}
	}
	>a{
		width: 100%;
		height: auto;
		&:hover, &:focus, &:target{
			opacity: .8;
		}
	}
	.mfp-close{
		opacity: 1;
		color: $white;
		font-size: rems(40);
	}

	.sidepopup{
		display: flex;
		align-items: stretch;
		flex-direction: row-reverse;
		flex-wrap: wrap;
		@media screen and (max-width: 700px){
			flex-direction: column;
			max-width: rems(500);
			margin: 0 auto;
			>div{
				flex: auto;
			}
		}
		.btn{}
		>div{
			flex: 1;
			background-image: url('../img/new-music-bg.jpg');
			// background: $white;
			background-size: cover;
			p{
				text-transform: uppercase;
			}
			&:nth-child(1){
				img{
					width: 100%;
					display: block;
				}
			}
			&:nth-child(2){
				// text-shadow: 0 0 rems(5) #0008;
				flex: .8;
				padding: rems(30);
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				h2.heading{
					font-size: rems(50);
					text-transform: none;
					margin-bottom: 0;
					text-shadow: 0 0 rems(10) #000A;
				}
				>*{
					margin-top: rems(20);
				}
			}
		}
	}

	.sidepopup2{ 
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		@media screen and (max-width: 700px){
			flex-direction: column;
			max-width: rems(500);
			margin: 0 auto;
			>div{
				flex: auto;
			}
		}
		.btn{
			color: $white; 
			text-shadow: 0 0 rems(10) #000A;
			background-color: #f58a1f;
			@include hover-focus{
				background-color: $black;
			}
		}
		>div{
			flex: 1;
			// background-image: url('../img/new-music-bg.jpg');			
			background-color: $white;
			background-size: cover;
			p{
				text-transform: uppercase;
			}
			&:nth-child(1){
				img{
					width: 100%;
					display: block;
				}
			}
			&:nth-child(2){
				// text-shadow: 0 0 rems(5) #0008;
				flex: .8;
				padding: rems(30);
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				h2.heading{
					font-size: rems(40);
					letter-spacing: 0;
					text-transform: none;
					color: #633083;
					margin-bottom: 0;
				}
				>*{
					margin-top: rems(20);
				}
			}
		}
	}
}
