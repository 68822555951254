header {
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  .container{
    max-width: rems(1300);
    text-align: center;
    width: 100%;    
    nav{
      a, div{
        min-width: 120px;
        font-size: rems(18);
        @media screen and (max-width: 1200px){
          font-size: rems(14);
        }
      }
      .dropdown{
        position: absolute;
        transition: .3s all;
        a{
          display: block;
          padding-top: 0;
        }
        a:nth-child(n+2){
          opacity: 0;
          pointer-events: none;
          transition: .3s all;
        }
        &:active, &:focus, &:focus-within, &:hover{
          background-color: $cyan;

          a:nth-child(n+2){
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
    .logo-mobile{
      width: 100px;
      display: block;
      margin-bottom: 20px;
      margin: rems(40) auto 20px;
    }
    .logo-desktop{
      display: none
    }
    .menu-trigger{
      display: block;
      border: none;
      border-top: solid 2px $white;
      border-bottom: solid 2px $white;
      background-color: transparent;
      width: 40px; 
      height: 30px;
      position: relative;
      margin: 0 auto;
      transition: all .4s;
      cursor: pointer;
      &::after,&::before{
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        background-color: $white;
        top: calc(50% - 1px);
        left: 0;
        transition: all .4s;
      }
      &.active{
        border-color: transparent;
        &::after{
          transform: rotate(45deg)
        }
        &::before{
          transform: rotate(-45deg)
        }
      }
    }
    .menu{
      @include flex(column,center,center);
      position: absolute;
      width: calc(100% - 30px);
      max-width: 300px;
      left: 50%;
      transform: translateX(-50%);
      top: 162px;
      opacity: 0;
      transition: all .4s;
      pointer-events: none;
      a{
        display: block;
        text-transform: uppercase;
        line-height: 1;
        font-weight: bold;
        color: $black;
        width: 100%;
        padding: 10px;
        // &:nth-child(5){
        //   opacity: 0;
        //   pointer-events: none;

        // }
        @include hover-focus{
          color: $white;
        }
      }
      &.active{
        background-color: $cyan;
        opacity: 1;
        pointer-events: all;
      }

    }
  }

  
  .social{
    position: absolute;
    top: rems(20);
    right: rems(30);
    color: $white;
    font-size: rems(24);
    width: rems(400);
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include tablet-down{
      right: 0;
      left: 0;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
    a{
      display: inline-block;
      margin: rems(5);
    }
    svg{
      width: 1.3em;
      height: auto;
      display: block;
    }
    path{
      fill: $white;
      transition: .3s all;
    }
    a{
      @include hover-focus{
        color: $pink;
        path{
          fill: $pink;
        }
      }
    }
  }

  @include desktop-sm-up{
    .container{
      .logo-mobile{
        display: none;
      }
      .logo-desktop{
        display: block;
        width: rems(270);
      }
      .menu-trigger{
        display: none;
      }
      .menu{
        transform: none;
        @include flex(row,space-between, center);
        opacity: 1;
        pointer-events: all;
        position: initial;
        max-width: 100%;
        background: transparent !important;
        a{
          background-color: transparent;
          color: $black;
          max-width: max-content;
          @include hover-focus{
            color: $white;
          }
        }
      }
    }
  }
}