.music{
  padding: 60px 0;
  background-color: $white;
  background-image: url('../../dist/img/new-music-bg.jpg');
  background-position: bottom center;
  background-size: cover;
  .container{
    max-width: 100%;
  }
  .music-slider{
    overflow: hidden;
    position: relative;
    .music-item{
      text-align: center;
      transition: all .4s;
      .cover{
        overflow: hidden;
        width: 100%;
        max-height: 446px;
        max-width: 360px;
        display: block;
        margin: 0 auto 20px;
        border: solid 2px $white;
        transition: all .4s;
        img{
          max-width: 100%;
          transition: all .4s;
        }
        @include hover-focus{
          img{
            transform: scale(1.05);
          }
        }
      }
    }
    .slick-slide {
      margin: 0 20px;
      opacity: 0;
      transition: all .4s;
      &.slick-active{
        opacity: 1;
      }
    }
    
    /* the parent */
    .slick-list {
      margin: 0 -20px;
    }

    .slick-arrow{
      background-color: transparent;
      border: none;
      font-size: 20px;
      color: $white;
      position: absolute;
      top: calc(50% - 10px);
      z-index: 999;
      &.slick-prev{
        left: 0;
      }
      &.slick-next{
        right: 0;
      }
    }
  }
  
  @include tablet-up{
    padding: 120px 0;
    .music-slider{
      padding: 0 20px;
      .music-item{
        padding: 42px 0;
        &.slick-center{
          padding: 0;
          .cover{
            max-width: 446px;
          }
        }
      }
    }
  }
}