.shop{
  padding: 60px 0;
  background-color: $black;
  background-image: url('../../dist/img/shop-bg-seamless.jpg');
  background-position: center;
  background-size: 100% auto;
  background-attachment: scroll;
  @include tablet-up{
    padding: 120px 0;
    background-size: cover;
    background-attachment: fixed;
  }
}