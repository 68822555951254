footer{
  padding: 60px 0;
  background-color: $black;

  background-image: url('../img/footer-bg.jpg');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  color: $white;
  text-align: center;

  .top{
    font-size: rems(39);
    margin-bottom: 20px;
    font-family: $header-font;
    p{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img{
      margin: 0 rems(10);
    }
    @media screen and (max-width: 500px){
      font-size: rems(26);
    }
  }

  .social{
    margin-bottom: 20px;
    @include flex(row, center, center);

    svg{
      width: 1.25em;
      height: 1.25em;
      path{
          fill: $pink;
      }
    }
    a{
      display: block;
      color: $pink;
      font-size: 40px;
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
      @media screen and (max-width: 360px){
        font-size: 30px;
      }
    }
  }

  .copyright{
    font-size: 14px;
    max-width: 900px;
    display: inline-block;
    line-height: 1.2;
    p{
      &:first-child{
        margin-bottom: 20px;
      }
    }
  }
  
  @include tablet-up{
    padding: 120px 0;
    .copyright{
      font-size: 18px;
    }
  }
}