.hero{
  height: 100vh;
  max-height: 120vw;
  position: relative;
  img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}