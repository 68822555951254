.follow{
  padding: 60px 0;
  background-color: $white;
  background-image: url('../img/ig-bg.jpg');
  background-size: cover;
  background-position: center;
  h2.heading{
    color: $black;
  }
  @include tablet-up{
    padding: 120px 0;
  }
}