$fa-font-path: "../font";
$html-font-size: 16;
$pink: #FA829E;
$cyan: #63E3D6;
$white: #F8EEDA;
$black: #272727;

@font-face {
    font-family: 'Candice';
    src: url('../font/Candice.woff2') format('woff2'),
        url('../font/Candice.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$primary-font: 'henriette', serif;
$header-font: 'Candice', serif;